import { CroppedImageDimensions } from "../App";
import axios from 'axios';

const cropGifUrl: string = "https://api.gif2slack.com/crop/"
const apiKey = "AhTm4pqCEW5OHvvWkpWd67vniNYHPfiq63Q96HgJ"

interface CropGifPostResponse
{
    gifUrl: string
}

export class CropGifApi {
    
    static async CropGif(gifUrl: string, cropDimensions: CroppedImageDimensions): Promise<string | null>
    {
        try
        {
            const cropGifResponse = await axios.post(cropGifUrl, { gifUrl, ...cropDimensions }, { headers: { 'x-api-key': apiKey }})
            return cropGifResponse["data"]["gifUrl"];
        }
        catch (error)
        {
            console.error(error)
            return null;
        }
    }
}

