import { Form, Input, Button, Checkbox } from 'antd';
import React from 'react';

interface UrlInputComponentProps
{
    setGifUrl: React.Dispatch<React.SetStateAction<string>>
}

export const UrlInputComponent: React.FC<UrlInputComponentProps> = (props) => {

    const onFieldsChange = (values: any) => {
        const isError = values[0]['errors'].length != 0;
        if (isError) {
            return;
        }

        const gifUrl = values[0].value as string;
        props.setGifUrl(gifUrl);
      };
    
    return (
        <div style={{maxWidth: '700px', width: '100%'}}>
            <Form onFieldsChange={onFieldsChange}>
                <div style={{paddingBottom: '2px'}}>Enter a GIF URL:</div>
                <Form.Item
                    style={{width: 'auto'}}
                    name="gifUrl"
                    rules={[{ required: true, type: 'url', message: 'Enter a valid Giffy URL!' }]}
                >
                    <Input placeholder="https://..."/>
                </Form.Item>
            </Form>
        </div>
    );
}