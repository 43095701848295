import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FileImageOutlined } from '@ant-design/icons';
import { CroppedImageDimensions } from '../App';
import { Spin } from 'antd';

function generateDownload(canvas: any, crop: any) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob: any) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = 'cropPreview.gif';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/gif',
    1
  );
}

interface GifDisplayComponentProps
{
    gifUrl: string,
    isDisabled: boolean,
    setCroppedImageDimensions: React.Dispatch<React.SetStateAction<CroppedImageDimensions | null>>
}

export const GifDisplayComponent: React.FC<GifDisplayComponentProps> = (props) => {
  //const previewCanvasRef = useRef<any>(null);
  
  const [upImg, setUpImg] = useState<any>();
  const imgRef = useRef<any>(null);
  const [crop, setCrop] = useState<any>({ unit: 'px', width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [maxImageWidth, setMaxImageWidth] = useState<number>(300)
  const [wasLoading, setWasLoading] = useState<boolean>(false)

  const maxImageHeight = 320

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    setMaxImageWidth(img.naturalWidth * (maxImageHeight / img.naturalHeight));
    imgRef.current = img;
    setIsLoading(false)
    setWasLoading(true); 
  }, []);

  useEffect(() => {
    if (props.gifUrl == null || props.gifUrl == "") {
        return;
    }
    
    setIsLoading(true)
    setUpImg(props.gifUrl)

  }, [props.gifUrl])

  useEffect(() => {
    let cropToUse = completedCrop;

    if (props.setCroppedImageDimensions == null || imgRef == null || imgRef.current == null) {
      props.setCroppedImageDimensions(null)
    }

    if (wasLoading) {
      setWasLoading(false);
      const minDimension = 0.8 * (imgRef.current.width < imgRef.current.height ? imgRef.current.width : imgRef.current.height);
      const newCrop = { width: minDimension, height: minDimension, unit: 'px', aspect: 1, x: imgRef.current.width / 2 - minDimension / 2, y: imgRef.current.height / 2 - minDimension /2}
      setCrop(newCrop);
      cropToUse = newCrop
    }

    // Don't allow invalid crops
    if (cropToUse == null || cropToUse.width < 20 || cropToUse.height < 20) {
      props.setCroppedImageDimensions(null);
      return;
    }

    // Scale up the dimension
    const dimensions: CroppedImageDimensions = {
      x: cropToUse.x,
      y: cropToUse.y, 
      width: cropToUse.width,
      height: cropToUse.height
    }

    dimensions.x = dimensions.x * (imgRef.current.naturalWidth / imgRef.current.width)
    dimensions.y = dimensions.y * (imgRef.current.naturalHeight / imgRef.current.height)

    dimensions.width = dimensions.width * (imgRef.current.naturalWidth / imgRef.current.width)
    dimensions.height = dimensions.height * (imgRef.current.naturalHeight / imgRef.current.height)

    props.setCroppedImageDimensions(dimensions as CroppedImageDimensions)

  }, [completedCrop]);

  if (props.gifUrl == "") {
    return (
      <div style={{height: maxImageHeight, width: '100%', maxWidth: '600px', background: 'lightgrey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FileImageOutlined style={{fontSize: 100, color: 'grey'}} />
      </div>
    )
  }

  return (
    <div style={{width: isLoading ? '100%' : 'auto', maxWidth: '600px'}}>
      <div style={{height: maxImageHeight, width: '100%', maxWidth: '600px', background: 'lightgrey', display: isLoading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <Spin >
          <div style={{width: '100%'}}>
            <FileImageOutlined style={{fontSize: 100, color: 'grey'}} />
          </div>
        </Spin> 
      </div>
      <div style={{maxWidth: maxImageWidth, height: maxImageHeight, display: isLoading ? 'none' : 'flex', alignItems: 'center', borderRadius: '5px', justifyContent: 'center', background: 'rgba(211, 211, 211, 0.2)'}}>
        <div style={{maxWidth: maxImageWidth, maxHeight: maxImageHeight}}>
          <ReactCrop
            keepSelection={true}
            minWidth={20}
            minHeight={20}
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            disabled={props.isDisabled}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
        </div>
      </div>
    </div>

  );
}
