import { Avatar } from 'antd';
import { UserOutlined,SlackOutlined, FileImageOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';


interface SlackGifDisplayComponentProps
{
    slackGifUrl: string
}

export const SlackGifDisplayComponent: React.FC<SlackGifDisplayComponentProps> = (props) => {
    const debugUrl = "https://slack2gif.s3-ap-southeast-2.amazonaws.com/slack.PNG"
    
    const isDebug = false;
    const bgColor = isDebug ? "red" : "transparent"
    const opacity = isDebug ? '20%' : '0%';
    const currentDate = new Date();
    const [dateString, setDateString] = useState<string>(`${currentDate.getHours() % 12}:${currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes()} ${currentDate.getHours() < 12 ? 'AM' : 'PM'}`)
    const [gridKey, setGridKey] = useState<string>(Math.random().toString())
    const [slackUsername, setSlackUsername] = useState<string>("-")

    const slackUsernames = [
        "Clark Kent",
        "Barack Obama",
        "Leonardo da Vinci",
        "Bruce Wayne",
        "Kanye West",
        "Stewart Butterfield",
        "Cal Henderson",
        "Madonna Ciccone",
        "Cleopatra Philopator",
        "Steve Jobs",
        "Bill Gates",
        "Elizabeth Windsor",
        "Marie Curie"
    ]

    const gridRef = useRef(null);
    useEffect(() => {
        if (props.slackGifUrl == "") {
            return;
        }

        setSlackUsername(slackUsernames[Math.floor((slackUsernames.length - 1) * Math.random())])
        setDateString(`${currentDate.getHours() % 12}:${currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes()} ${currentDate.getHours() < 12 ? 'AM' : 'PM'}`)
        setGridKey(Math.random().toString())
    }, [props.slackGifUrl])
    
    return (
        <div>
            <div>
                <div key={gridKey} ref={gridRef} style={{zIndex: 200, position: isDebug ? 'absolute' : 'relative', display: "grid", gridTemplateColumns: "13px 36px 7px 200px", gridTemplateRows: "5px 110px", animation: props.slackGifUrl == "" ? "none" : "headShake", animationDuration: "1.5s" }}>
                    <div style={{background: bgColor, gridColumn: 1, gridRow: 2 }}></div>
                    <div style={{gridRow: 2, gridColumn: 2}}>
                        <Avatar style={{marginTop: '1px', borderRadius: '3px',  background: props.slackGifUrl == "" ? "rgb(204, 204, 204)" : "green"}} shape="square" size={36} icon={<SlackOutlined />} />
                    </div>
                    <div style={{background: bgColor, gridColumn: 3, gridRow: 2 }}></div>
                    <div style={{background: bgColor, gridColumn: 4, gridRow: 2 }}>
                        <div>
                            <div style={{marginTop: '-4px', marginLeft: '1px'}}>
                                <span style={{width: '50px', marginTop: '100px', fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                                    {props.slackGifUrl != "" ? slackUsername : "-"}
                                </span>
                                <span style={{marginTop: '2px', marginLeft: '10px', width: '50px', fontFamily: 'sans-serif', fontSize: '11.4px', color: 'grey'}}>
                                    {dateString}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div style={{marginLeft: '3px', marginTop: '0px', marginBottom: '8px'}}> 
                                <div style={{borderRadius: 3, display: props.slackGifUrl == "" ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', background: 'lightgrey', width: 32, height: 32}}>
                                    <FileImageOutlined style={{fontSize: 24, color: 'grey'}}  />
                                </div>
                                <div style={{display: props.slackGifUrl == "" ? 'none' : 'initial'  }}>
                                    <img style={{display: props.slackGifUrl == "" ? 'none' : 'initial', height: '32px'}} src={props.slackGifUrl} />
                                </div>
                            </div>
                            <span style={{fontFamily: 'sans-serif', fontSize: '14.4px', marginLeft: '2px'}}>
                                {props.slackGifUrl != "" ? "What a great Slack emoji!!" : "Format a GIF to preview!"}
                            </span>
                            <span>
                                <img style={{marginLeft: '6px', marginTop: '-6px', height: '22px'}} src={props.slackGifUrl} />
                            </span>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span>
                                <div style={{display: 'flex', alignItems: 'center', background: 'rgba(29,155,209,.1)', height: '22px', width: '40px', borderRadius: '20px', boxShadow: 'inset 0 0 0 1px rgba(var(--sk_highlight_accent,29,155,209),1)'}}>
                                    <span>
                                        <img style={{marginLeft: '7.1px', marginTop: '-3px', height: '16px'}} src={props.slackGifUrl} />
                                    </span>
                                    <span style={{fontFamily: 'sans-serif', marginTop: '1px', marginLeft: '5px', fontSize: '11px', color: 'rgb(18, 100, 163)'}}>
                                        {props.slackGifUrl != "" ? "9" : "-"}
                                    </span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div style={{position: "absolute", opacity: opacity, zIndex: 201}}>
                    <img src={debugUrl} />
                </div> */}

            </div>
        </div>
    );

}