import React, { useState } from 'react';
import logo from './logo.svg';
import { Button } from 'antd';
import { Card } from 'antd';
import { Layout } from 'antd';
import './App.css';
import '../node_modules/animate.css/animate.min.css'
import 'antd/dist/antd.css';
import { Typography } from 'antd';
import { UrlInputComponent } from './components/UrlInputComponent';
import { GifDisplayComponent } from './components/GifDisplayComponent';
import { SlackGifDisplayComponent } from './components/SlackGifDisplayComponent';
import { SlackOutlined } from '@ant-design/icons';
import { CropGifApi } from './api/CropGifApi';
import QueueAnim from 'rc-queue-anim';
import { SlackGifDownloadComponent } from './components/SlackGifDownloadComponent';
import { ReactComponent as SlackLogo } from './images/slack.svg';
import { ReactComponent as GifLogo } from './images/gif.svg';


const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

export interface CroppedImageDimensions {
  x: number,
  y: number,
  width: number,
  height: number
}

function App() {

  const [gifUrl, setGifUrl] = useState<string>("");
  const [slackGifUrl, setSlackGifUrl] = useState<string>("")
  const [croppedDimensions, setCroppedDimensions]  = useState<CroppedImageDimensions | null>(null)
  const [isGifProcessing, setisGifProcessing]  = useState<boolean>(false)

  let canProcessGif = gifUrl != null && gifUrl != "" && croppedDimensions != null;
  const cardBorderRadius = 15;

  let showSlackCard = slackGifUrl != "";

  const onProcessButtonClick = async () => {
    setisGifProcessing(true)

    if (croppedDimensions != null && gifUrl != "") {
      try
      {
        const slackGifUrl = await CropGifApi.CropGif(gifUrl, croppedDimensions);
        setisGifProcessing(false)
        setSlackGifUrl(slackGifUrl as string)
      }
      catch (error)
      {
        console.log(error)
      }
    }
  }
  
  return (
    <div>
    <Layout style={{height: '100vh', minHeight: '850px', background: '#3F0E40'}}>
      <Content style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', transition: 'all 0.3s ease-out', width: '100%'}}>
            <Card bodyStyle={{height: '100%', width: '100%'}} style={{ width: '90%', maxWidth: '800px', height: '90vh', minHeight: '820px', border: '0px', borderRadius: cardBorderRadius, transition: 'all 0.3s', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{display: 'grid', gridTemplateRows: '10% 63% 27%', height: '100%'}}>
                <div style={{gridRow: 1, display: 'flex', 'justifyContent': 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <div style={{fontSize: '64px', display: 'flex', justifyContent: 'center', 'alignItems': 'center'}}>
                    <GifLogo style={{height: 65, width: 'auto'}} />
                    <div style={{marginLeft: 10, marginRight: 10}}>Gif2Slack</div>
                    <SlackLogo style={{height: 60, marginLeft: 2.5, marginRight: 2.5, width: 'auto'}} />

                  </div>
                  <div style={{fontFamily: 'sans-serif', marginTop: '-10px', fontStyle: 'italic'}}>
                    Hassle free animated emojis for Slack!
                  </div>
                  </div>
                <div style={{gridRow: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',}}>
                  <UrlInputComponent setGifUrl={setGifUrl}/>
                  <GifDisplayComponent gifUrl={gifUrl} setCroppedImageDimensions={setCroppedDimensions} isDisabled={isGifProcessing}/>
                  <Button
                      style={{marginTop: '20px', display: 'flex', alignItems: 'center', background: 'rgba(29,155,209,.1)', borderRadius: '20px', boxShadow: 'inset 0 0 0 1px rgba(var(--sk_highlight_accent,29,155,209),1)'}}
                      type="dashed"
                      icon={<SlackOutlined />}
                      loading={isGifProcessing}
                      disabled={!canProcessGif}
                      onClick={onProcessButtonClick}
                  >
                      Format for Slack
                  </Button>
                </div>
                <div style={{gridRow: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', width: '100%' }}>
                  <div style={{marginBottom: 10, background: 'rgba(25, 25, 25, 0.05)', width: 'calc(100% + 48px)', display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 10}}>
                    <SlackGifDisplayComponent slackGifUrl={slackGifUrl}/>
                  </div>
                  <SlackGifDownloadComponent slackGifUrl={slackGifUrl}/>
                </div>
              </div>
            </Card>
            {/* <Card style={{display: showSlackCard ? 'initial' : 'none', alignItems: 'center', animation: 'slideInLeft', animationDuration: '2s' }}>
            </Card> */}
          </div>
      </Content>
      <Footer style={{height: '30px', padding: 0, display: 'flex', 'justifyContent': 'center', alignItems: 'center', background: 'transparent', fontSize: 12}}>
          <span style={{color: 'white', marginRight: 5}}>Icons made by <a href="https://www.flaticon.com/authors/iconixar" title="iconixar">iconixar</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.</span>
          <span style={{color: 'white'}}>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.</span>
      </Footer>
    </Layout>
    </div>
  );
}

export default App;
