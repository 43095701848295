import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface SlackGifDownloadComponent
{
    slackGifUrl: string
}

export const SlackGifDownloadComponent: React.FC<SlackGifDownloadComponent> = (props) => {

    let isButtonEnabled = props.slackGifUrl != "";
    
    return (
        <a href={props.slackGifUrl} download="slack.gif">
            <Button disabled={!isButtonEnabled} type="dashed" shape="round" icon={<DownloadOutlined />} size='large' style={{background: 'rgba(29,155,209,.1)', borderRadius: '20px', boxShadow: 'inset 0 0 0 1px rgba(var(--sk_highlight_accent,29,155,209),1)'}}>
                Download
            </Button>
        </a>
    )
}

